import { useMutation } from '@tanstack/react-query';

import PagesService from 'services/pages.service';

const useSendPageAnswer = () => {
  const { mutateAsync } = useMutation({
    mutationKey: ['send page answer'],
    mutationFn: async (params) => await PagesService.postPageAnswer(params),
  });

  return { mutateAsync };
};

export default useSendPageAnswer;
