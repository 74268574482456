const evaluateResult = (formAnswers, globalAnswers, saveResultCode) => {
  const data = formAnswers;
  const globalData = globalAnswers;

  try {
    // eslint-disable-next-line no-eval
    return eval(saveResultCode);
  } catch (e) {
    console.error(
      'Error evaluating result',
      saveResultCode,
      e,
      data,
      globalData
    );

    return true;
  }
};

export default evaluateResult;
