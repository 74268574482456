const componentsCache = new Map();

export const loadComponent = (moduleName, moduleVariable) => {
  return async () => {
    await __webpack_init_sharing__('default');

    const container = window.app1;

    // eslint-disable-next-line camelcase
    await container.init(__webpack_share_scopes__.default);

    const factory = await window.app1.get('./' + moduleName);
    const Module = factory();

    if (moduleVariable) {
      return Module[moduleVariable];
    }

    return Module;
  };
};

export const preloadComponent = (moduleName) => {
  if (!componentsCache.has(moduleName)) {
    const Module = loadComponent(moduleName);

    componentsCache.set(moduleName, Module);

    return Module;
  }
};
