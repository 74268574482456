import * as amplitude from '@amplitude/analytics-browser';

const useAmplitude = (isDebugMode) => {
  const initAmplitude = (amplitudeKey) => {
    if (amplitudeKey) {
      amplitude.init(amplitudeKey, {
        defaultTracking: {
          sessions: true,
          pageViews: false,
          formInteractions: false,
          fileDownloads: false,
        },
      });
    }
  };

  const logAmplitudeEvent = (event) => {
    if (event) {
      amplitude.logEvent(event.event_type);
    }
  };

  const triggerAmplitudeSetUserIdEvent = (userId) => {
    if (userId) {
      amplitude.setUserId(userId);
    }
  };

  const triggerAmplitudeIdentifyEvents = (events) => {
    const identifyEvent = new amplitude.Identify();

    events?.forEach((event) => {
      identifyEvent.setOnce(event[0], event[1]);
    });
    amplitude.identify(identifyEvent);

    if (isDebugMode) {
      console.log('amplitude identify', events);
    }
  };

  const triggerAmplitudeTrackEvents = (events) => {
    events?.forEach((event) => {
      amplitude.track(event.event_type, event.event_properties);

      if (isDebugMode) {
        console.log(
          'amplitude event',
          event.event_type,
          event.event_properties
        );
      }
    });
  };

  return {
    initAmplitude,
    logAmplitudeEvent,
    triggerAmplitudeSetUserIdEvent,
    triggerAmplitudeIdentifyEvents,
    triggerAmplitudeTrackEvents,
  };
};

export default useAmplitude;
