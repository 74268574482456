import axios from 'axios';
import { parseCookies } from 'nookies';

const cookies = parseCookies();

const axiosClassic = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_API_URL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    'Client_device_id': cookies.client_device_id,
  },
});

export default axiosClassic;
