const useFacebookPixel = (isDebugMode) => {
  // Save Facebok pixel ids to local state in order to track events for every
  // single one of them
  const initFacebookPixel = (ids) => {
    if (ids) {
      if (Array.isArray(ids)) {
        ids.forEach((id) => fbq('init', id));
      } else {
        fbq('init', ids);
      }

      if (isDebugMode) {
        console.log('fb init', ids);
      }
    }
  };

  // Trigger event for every Facebook pixel id
  const triggerFacebookPixelEvent = (events) => {
    events?.forEach((event) => {
      fbq(event.action, event.event_name, event.event_properties);

      if (isDebugMode) {
        console.log(
          'fb event',
          event.action,
          event.event_name,
          event.event_properties
        );
      }
    });
  };

  return { initFacebookPixel, triggerFacebookPixelEvent };
};

export default useFacebookPixel;
