import { useState, useEffect } from 'react';

import getOnboardingsLibraryUrl from 'utils/mf/getOnboardingsLibraryUrl.mjs';

const useLoadRemoteScript = () => {
  const [isRemoteScriptLoaded, setIsRemoteScriptLoaded] = useState(false);

  useEffect(() => {
    const element = document.createElement('script');
    const onboardingsLibraryUrl = getOnboardingsLibraryUrl();

    element.src = `${onboardingsLibraryUrl}/remoteEntry.js`;
    element.type = 'text/javascript';
    element.async = true;

    setIsRemoteScriptLoaded(false);

    element.onload = () => {
      setIsRemoteScriptLoaded(true);
    };

    element.onerror = () => {
      setIsRemoteScriptLoaded(false);
    };

    document.head.appendChild(element);

    return () => {
      document.head.removeChild(element);
    };
  }, []);

  return { isRemoteScriptLoaded };
};

export default useLoadRemoteScript;
