import evaluateResult from 'utils/helpers/evaluation/evaluateResult';
import getObjectById from 'utils/helpers/getObjectById';

const evaluateNextPage = (
  pagesData,
  nextPagesData,
  formAnswers,
  globalAnswers
) => {
  const foundNextPageData = nextPagesData.find((nextPage) => {
    return evaluateResult(formAnswers, globalAnswers, nextPage.condition);
  });

  if (foundNextPageData.url) {
    return { url: foundNextPageData.url };
  }

  const nextPageId =
    foundNextPageData?.next_page_id ?? nextPagesData[0]?.next_page_id;

  return getObjectById(nextPageId, pagesData.variant.pages);
};

export default evaluateNextPage;
