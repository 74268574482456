const formDataToObject = (formData) => {
  const obj = {};

  // eslint-disable-next-line prefer-const
  for (let [key, value] of formData.entries()) {
    if (key.endsWith('[]')) {
      key = key.substring(0, key.length - 2);
    }

    if (obj.hasOwnProperty(key)) {
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }

  return obj;
};

export default formDataToObject;
