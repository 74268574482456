const getComponentProps = (globalAnswers, mergedSettings, pageProps) => {
  const interpolateString = (template, fallback = '') => {
    return template.replace(/\{\{(.*?)\}\}/g, (match, name) => {
      return globalAnswers.hasOwnProperty(name)
        ? globalAnswers[name]
        : fallback;
    });
  };

  const interpolateAnswersString = (template, fallback = '') => {
    return template.replace(/\{\{answers.(.*?)\}\}/g, (match, name) => {
      return globalAnswers.hasOwnProperty(name)
        ? globalAnswers[name]
        : fallback;
    });
  };

  const interpolateSettingsString = (template, fallback = '') => {
    return template.replace(/\{\{settings.(.*?)\}\}/g, (match, name) => {
      return mergedSettings.hasOwnProperty(name)
        ? mergedSettings[name]
        : fallback;
    });
  };

  const interpolate = (template) => {
    const firstStep = interpolateSettingsString(template, '');
    const secondStep = interpolateAnswersString(firstStep, '');
    return interpolateString(secondStep, '');
  };

  const props = {};

  for (const [key, value] of Object.entries(pageProps)) {
    if (typeof value === 'string') {
      props[key] = interpolate(value);
    } else {
      props[key] = value;
    }
  }

  return props;
};

export default getComponentProps;
