const parseCookieString = (str) => {
  const cookiesStringsArray = str.split(';');
  const cookiesObject = {};

  cookiesStringsArray.forEach((str) => {
    const keyValueArray = str.split('=');

    cookiesObject[decodeURIComponent(keyValueArray[0].trim())] =
      decodeURIComponent(keyValueArray[1].trim());
  });

  return cookiesObject;
};

export default parseCookieString;
