import { useMutation } from '@tanstack/react-query';

import PagesService from 'services/pages.service';

const useSendPageView = () => {
  const { isPending, mutateAsync } = useMutation({
    mutationKey: ['send page view'],
    mutationFn: async (params) => await PagesService.postPageView(params),
  });

  return { isPending, mutateAsync };
};

export default useSendPageView;
