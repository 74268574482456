import { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { parseCookies, setCookie } from 'nookies';

import posthog from 'posthog-js';

import useGetPagesData from 'hooks/api/useGetPagesData';
import useSendPageAnswer from 'hooks/api/useSendPageAnswer';
import useSendPageView from 'hooks/api/useSendPageView';

import useLoadRemoteScript from 'hooks/mf/useLoadRemoteScript';

import useAnalytics from 'hooks/analytics/useAnalytics';
import useAmplitude from 'hooks/analytics/useAmplitude';

import parseCookieString from 'utils/helpers/parseCookieString';
import getObjectById from 'utils/helpers/getObjectById';

import {
  loadComponent,
  preloadComponent,
} from 'utils/helpers/loading/componentLoader';
import preloadImage from 'utils/helpers/loading/preloadImage';

import formDataToObject from 'utils/helpers/formDataToObject';

import evaluateResult from 'utils/helpers/evaluation/evaluateResult';
import evaluateNextPage from 'utils/helpers/evaluation/evaluateNextPage';

import getLayoutProps from 'utils/helpers/props/getLayoutProps';
import getComponentProps from 'utils/helpers/props/getComponentProps';

import getServerSideProps from 'utils/server/getServerSideProps';

import getOnboardingsLibraryUrl from 'utils/mf/getOnboardingsLibraryUrl.mjs';

export { getServerSideProps };

const Page = ({ href, cookieString, localStorageString }) => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const params = router.query;

  // API request handlers
  const { data: pagesData } = useGetPagesData(href);
  const { mutateAsync: sendPageAnswer } = useSendPageAnswer();
  const { isPending: isPageViewPending, mutateAsync: sendPageView } =
    useSendPageView();

  // Components from remote application
  const [SettingsProvider, setSettingsProvider] = useState(null);
  const [CurrentComponent, setCurrentComponent] = useState(null);
  const [CurrentLayout, setCurrentLayout] = useState(null);

  // Other local states
  const [globalAnswers, setGlobalAnswers] = useState({});
  const [mergedSettings, setMergedSettings] = useState({});
  const [currentPageData, setCurrentPageData] = useState(null);

  // Handle analytics
  const { initAnalytics, triggerAnalyticalEvents } = useAnalytics();
  const { logAmplitudeEvent } = useAmplitude();

  // Refs
  const lastClickedButton = useRef(null);

  // Handle images preload
  const preloadNextPageImages = (page) => {
    const pageButtons = page.props.buttons;
    const pageImage = page.props.imageUrl;
    const pageLogo = page.props.logoUrl;

    if (pageButtons) {
      pageButtons.forEach((button) => {
        const iconUrl = button.iconUrl;

        if (iconUrl) {
          preloadImage(iconUrl);
        }
      });
    }

    if (pageImage) {
      preloadImage(pageImage);
    }

    if (pageLogo) {
      preloadImage(pageLogo);
    }
  };

  // Preload next pages
  const preloadNextPages = (nextPages) => {
    if (nextPages) {
      for (const page of nextPages) {
        const pageById = getObjectById(
          page.next_page_id,
          pagesData.variant.pages
        );

        if (pageById) {
          const pageLayout = pageById.props?.layout_name;

          preloadNextPageImages(pageById);

          // Preload component itself
          preloadComponent(pageById.name);

          if (pageLayout) {
            // Preload component layout
            preloadComponent(pageLayout);
          }
        }
      }
    }
  };

  // Handle every click on the document
  const handleClick = useCallback((e) => {
    lastClickedButton.current = e.target.closest('button, input[type=submit]');
  }, []);

  // Handle answer submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const formAnswers = formDataToObject(new FormData(e.target));
    const buttonElement = lastClickedButton.current;

    if (buttonElement) {
      formAnswers[buttonElement.name] = buttonElement.value;
    }

    const mergedAnswers = {
      ...globalAnswers,
      ...evaluateResult(
        formAnswers,
        globalAnswers,
        currentPageData.saveResultCode
      ),
    };

    setGlobalAnswers(mergedAnswers);

    // Redirect to the next page based on form answers
    const nextPage = evaluateNextPage(
      pagesData,
      currentPageData.nextPages,
      formAnswers,
      mergedAnswers
    );

    if (searchParams.get('debug_answers')) {
      const text =
        `globalData: ${JSON.stringify(mergedAnswers, null, 2)}\n` +
        `data: ${JSON.stringify(formAnswers, null, 2)}\n` +
        `Next Page: ${JSON.stringify(nextPage.internalName, null, 2)}`;

      // eslint-disable-next-line no-alert
      alert(text);

      return;
    }

    if (nextPage.id) {
      localStorage.setItem('currentPageId', nextPage.id);
      router.push(`/${params.onboardingId}/${nextPage.id}`, undefined, {
        shallow: true,
      });
    } else if (nextPage.url) {
      window.location.href = nextPage.url;
    }

    sendPageAnswer({
      href: href,
      body: {
        page_id: currentPageData.id,
        answers: formAnswers,
        global_answers: mergedAnswers,
        cookie: document.cookie,
        localstorage_data: JSON.stringify(localStorage),
      },
    })
      .then((data) => {
        if (data?.events) {
          triggerAnalyticalEvents(data.events);
        }
      })
      .catch(console.error)
      .finally(() => (lastClickedButton.current = null));
  };

  // Handle analytical events
  const handleAnalyticalEvent = useCallback((e) => {
    const eventData = e.data;

    if (eventData?.service && eventData?.event_type) {
      switch (eventData.service) {
        case 'amplitude':
          logAmplitudeEvent(eventData);
          break;

        case 'posthog':
          posthog.capture(eventData.event_type);
          break;

        case 'facebook':
          fbq('track', eventData.event_type);
          break;

        default:
          return null;
      }
    }
  }, []);

  // Handle redirect events
  const handleRedirectEvent = useCallback(
    (e) => {
      if (pagesData) {
        const eventDetail = e.detail;

        if (eventDetail) {
          const pageRole = eventDetail.role;
          const pageByRole = pagesData.variant.pages.find(
            (page) => page.role === pageRole
          );

          if (pageByRole) {
            router.push(`/${params.onboardingId}/${pageByRole.id}`);
          }
        }
      }
    },
    [pagesData, router, params.onboardingId]
  );

  // Load remote script
  const { isRemoteScriptLoaded } = useLoadRemoteScript();

  // Initial components load
  useEffect(() => {
    if (pagesData && typeof window !== 'undefined' && isRemoteScriptLoaded) {
      setGlobalAnswers(pagesData.variant.global_answers || {});

      // Set background color for current theme
      document.body.style.backgroundColor = pagesData.variant.background_color;

      // Init analytics
      initAnalytics({
        amplitudeKey: pagesData.variant.settings.amplitude_key,
        facebookPixelIds:
          pagesData.variant.settings.facebook_pixel_ids ||
          pagesData.variant.settings.facebook_pixel_id,
      });

      // Trigger necessary analytical events
      triggerAnalyticalEvents(pagesData.events);

      // Load SettingsProvider since it is used on every page
      setSettingsProvider(
        dynamic(loadComponent('SettingsContext', 'SettingsProvider'), {
          ssr: false,
        })
      );

      // Add necessary event listeners to the document
      document.addEventListener('click', handleClick);

      // Remove the event listeners after component is unmounted or of the
      // dependencies have changed
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [pagesData, isRemoteScriptLoaded]);

  // Handle page change
  useEffect(() => {
    if (pagesData && isRemoteScriptLoaded) {
      const pageData = getObjectById(params.pageId, pagesData.variant.pages);

      if (pageData) {
        setCurrentPageData(pageData);
        preloadNextPages(pageData.nextPages);

        // Save merged settings for settings context
        setMergedSettings({
          ...pagesData.variant.settings,
          ...pageData.settings,
        });

        if (!isPageViewPending) {
          // Send current view to the server and handle received events
          sendPageView({
            href: href,
            body: {
              page_id: pageData.id,
            },
          }).then((data) => triggerAnalyticalEvents(data.events));
        }

        // Set current page layout and component
        setCurrentLayout(
          pageData.props.layout_name
            ? dynamic(loadComponent(pageData.props.layout_name), {
                ssr: false,
              })
            : null
        );
        setCurrentComponent(
          dynamic(loadComponent(pageData.name), {
            ssr: false,
          })
        );
      }
    }
  }, [pagesData, isRemoteScriptLoaded, params.pageId]);

  // Add client device id to the url as query param
  useEffect(() => {
    const cookies = parseCookies();

    if (!router.query.client_device_id) {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            client_device_id: cookies.client_device_id,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router]);

  // Set localStorage and cookie data from the server
  useEffect(() => {
    if (cookieString) {
      const cookiesObject = parseCookieString(cookieString);

      if (router.query.client_device_id) {
        setCookie(null, 'client_device_id', router.query.client_device_id, {
          path: '/',
        });
      }
      // for (const key in cookiesObject) {
      //   if (key !== 'client_device_id' || !router.query.client_device_id) {
      //     setCookie(null, key, cookiesObject[key], {
      //       path: '/',
      //     });
      //   } else {
      //     setCookie(null, key, router.query.client_device_id, {
      //       path: '/',
      //     });
      //   }
      // }
    }

    if (localStorageString) {
      const localStorageObject = JSON.parse(localStorageString);

      for (const key in localStorageObject) {
        localStorage.setItem(key, localStorageObject[key]);
      }
    }
  }, [cookieString, localStorageString]);

  // Handle analytical events triggered from onboardings library
  useEffect(() => {
    window.addEventListener('serviceEvent', handleAnalyticalEvent);

    return () => {
      window.removeEventListener('serviceEvent', handleAnalyticalEvent);
    };
  }, [handleAnalyticalEvent]);

  // Handle redirect events triggered from onboardings library
  useEffect(() => {
    window.addEventListener('redirectEvent', handleRedirectEvent);

    return () => {
      window.removeEventListener('redirectEvent', handleRedirectEvent);
    };
  }, [handleRedirectEvent]);

  return (
    <form onSubmit={handleSubmit}>
      <Head>
        {typeof window !== 'undefined' && (
          <link
            rel="stylesheet"
            href={`${getOnboardingsLibraryUrl()}/out.css`}
          />
        )}
        {currentPageData?.title && <title>{currentPageData.title}</title>}
      </Head>
      {SettingsProvider && (
        <SettingsProvider settings={mergedSettings}>
          {CurrentComponent &&
            (CurrentLayout ? (
              <CurrentLayout
                {...getLayoutProps(pagesData.variant, currentPageData)}
                component={
                  <CurrentComponent
                    {...getComponentProps(
                      globalAnswers,
                      mergedSettings,
                      currentPageData.props
                    )}
                  />
                }
              />
            ) : (
              <CurrentComponent
                {...getComponentProps(
                  globalAnswers,
                  mergedSettings,
                  currentPageData.props
                )}
              />
            ))}
        </SettingsProvider>
      )}
    </form>
  );
};

export default Page;
