import { onboardingsLibraryUrls } from '../../constants/onboardingsLibraryUrls.mjs';

const getOnboardingsLibraryUrl = () => {
  const hasUrl = onboardingsLibraryUrls.hasOwnProperty(
    window.location.hostname
  );

  if (hasUrl) {
    return onboardingsLibraryUrls[window.location.hostname];
  }

  return process.env.NEXT_PUBLIC_ONBOARDINGS_LIBRARY_URL;
};

export default getOnboardingsLibraryUrl;
