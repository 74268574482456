import { useQuery } from '@tanstack/react-query';

import PagesService from 'services/pages.service';

const useGetPagesData = (href) => {
  const { data } = useQuery({
    queryKey: ['pages data'],
    queryFn: () => PagesService.getPagesData(href),
  });

  return { data };
};

export default useGetPagesData;
