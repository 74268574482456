import posthog from 'posthog-js';

import useAmplitude from './useAmplitude';
import useFacebookPixel from './useFacebookPixel';

const debug = true;

const useAnalytics = () => {
  const {
    initAmplitude,
    triggerAmplitudeSetUserIdEvent,
    triggerAmplitudeIdentifyEvents,
    triggerAmplitudeTrackEvents,
  } = useAmplitude(debug);
  const { initFacebookPixel, triggerFacebookPixelEvent } =
    useFacebookPixel(debug);

  const initAnalytics = ({ amplitudeKey, facebookPixelIds }) => {
    if (amplitudeKey) {
      initAmplitude(amplitudeKey);
    }

    if (facebookPixelIds) {
      initFacebookPixel(facebookPixelIds);
    }
  };

  const triggerAnalyticalEvents = (events) => {
    // Set user id tracking
    if (events.set_user_id) {
      posthog.identify(events.set_user_id);
      triggerAmplitudeSetUserIdEvent(events.set_user_id);

      if (debug) {
        console.log('setUserId', events.set_user_id);
      }
    }

    // Identify tracking
    if (events.identify_once?.length > 0) {
      triggerAmplitudeIdentifyEvents(events.identify_once);
    }

    // Posthog tracking
    if (events.posthog?.length > 0) {
      events.posthog.forEach((event) => {
        posthog[event.action](event.event_name, event.event_properties);

        if (debug) {
          console.log(
            'posthog event',
            event.event_type,
            event.event_properties
          );
        }
      });
    }

    // Amplitude tracking
    if (events.amplitude?.length > 0) {
      triggerAmplitudeTrackEvents(events.amplitude);
    }

    // Facebook pixel tracking
    if (events.facebook?.length > 0) {
      triggerFacebookPixelEvent(events.facebook);
    }
  };

  return {
    initAnalytics,
    triggerAnalyticalEvents,
  };
};

export default useAnalytics;
