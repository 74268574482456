import { parseCookies } from 'nookies';

import axiosClassic from 'utils/api/axiosInstances';

const PagesService = {
  async getPagesData(href, clientDeviceId) {
    const encodedHref = encodeURIComponent(href);
    const cookies = parseCookies();
    const clientDeviceIdFromCookies = cookies.client_device_id;

    const { data } = await axiosClassic.get(
      `/variants/48608944-9baf-424b-a6eb-1a1bab1936d9.json?pathname=${encodedHref}`,
      {
        headers: {
          Client_device_id: clientDeviceIdFromCookies
            ? clientDeviceIdFromCookies
            : clientDeviceId,
        },
      }
    );

    return data;
  },
  async postPageAnswer(params) {
    const { href, body } = params;
    const encodedHref = encodeURIComponent(href);

    const { data } = await axiosClassic.post(
      `/page_views/save?pathname=${encodedHref}`,
      body
    );

    return data;
  },
  async postPageView(params) {
    const { href, body } = params;
    const encodedHref = encodeURIComponent(href);

    const { data } = await axiosClassic.post(
      `/page_views/add?pathname=${encodedHref}`,
      body
    );

    return data;
  },
};

export default PagesService;
